import styled from '@emotion/styled';
import { useCallback } from 'react';
import { HospitalCuration } from '@/api/hospital';
import { ROUTES, ROUTE_QUERY_KEYS } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHospitalCuration from '@/hooks/useEventTrackerHospitalCuration';
import { useLoginModal } from '@/hooks/useLoginModal';
import { GLOBAL_COLOR } from '@/styles/colors';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { replaceBrokenThumbnail, toRem } from '@/utils/commonUtils';

interface HospitalQuickMenuCurationListProps {
  filteredHospitalCurationData: HospitalCuration[];
  curationTotalNames: string[];
  curationTotalCount: number;
}

const RECENT_VISITED_HOSPITAL = '최근방문';
const SCRAP_HOSPITAL = '스크랩병원';

const HospitalQuickMenuCurationList = ({
  filteredHospitalCurationData,
  curationTotalNames,
  curationTotalCount,
}: HospitalQuickMenuCurationListProps) => {
  const router = useAppRouter();
  const { isLogin } = useCheckLogin();
  const loginModalProps = useLoginModal();
  const { trackingClickHospitalReservationCurationItem } = useEventTrackerHospitalCuration();

  const handleCurationItemClick = useCallback(
    ({ listIndex, curationName, pathname }: { listIndex: number; curationName: string; pathname: string }) => {
      if (!isLogin()) {
        trackingClickHospitalReservationCurationItem({
          listIndex: filteredHospitalCurationData.length + listIndex,
          curationName,
          curationTotalNames,
          curationTotalCount,
        });
        loginModalProps.showModalWithCallback({
          onLogin: () => handleCurationItemClick({ listIndex, curationName, pathname }),
        });
        return;
      }

      trackingClickHospitalReservationCurationItem({ listIndex, curationName, curationTotalNames, curationTotalCount });
      router.push({ pathname, query: { [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname } });
    },
    [
      isLogin,
      router,
      filteredHospitalCurationData.length,
      curationTotalNames,
      curationTotalCount,
      loginModalProps,
      trackingClickHospitalReservationCurationItem,
    ],
  );

  const handleRecentVisitedHospitalClick = useCallback(() => {
    handleCurationItemClick({
      listIndex: 0,
      curationName: RECENT_VISITED_HOSPITAL,
      pathname: ROUTES.HOSPITALS.RECENT_VISITED,
    });
  }, [handleCurationItemClick]);

  const handleScrapHospitalClick = useCallback(() => {
    handleCurationItemClick({
      listIndex: 1,
      curationName: SCRAP_HOSPITAL,
      pathname: ROUTES.SCRAP.LIST,
    });
  }, [handleCurationItemClick]);

  return (
    <Container>
      <Content onClick={handleRecentVisitedHospitalClick}>
        <ImageContainer>
          <Image src={'/images/ic_recentVisit.png'} alt={'ic_recentVisit'} onError={replaceBrokenThumbnail} />
        </ImageContainer>
        <Text>{RECENT_VISITED_HOSPITAL}</Text>
      </Content>

      <Content onClick={handleScrapHospitalClick}>
        <ImageContainer>
          <Image src={'/images/ic_scrapHospital.png'} alt={'ic_scrapHospital'} onError={replaceBrokenThumbnail} />
        </ImageContainer>
        <Text>{SCRAP_HOSPITAL}</Text>
      </Content>
    </Container>
  );
};

export default HospitalQuickMenuCurationList;

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${toRem(8)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${toRem(4)};
  padding-right: ${toRem(4)};
  white-space: nowrap;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  border-radius: ${toRem(12)};
  background-color: ${GLOBAL_COLOR.GRAY_50};
  padding: ${toRem(6)} ${toRem(5.5)} ${toRem(6)} ${toRem(6.5)};
  margin-bottom: ${toRem(4)};
  position: relative;
  font-size: 0;

  &::after {
    content: 'MY';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 29%;
    opacity: 0.4;
    background-color: ${GLOBAL_COLOR.BLACK};
    border-radius: 0 0 ${toRem(12)} ${toRem(12)};
    color: white;
    font-size: ${toRem(10)};
    line-height: ${toRem(14)};
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Image = styled.img`
  width: ${toRem(36)};
  height: ${toRem(36)};
`;

const Text = styled.span`
  font-size: ${toRem(12)};
  font-weight: 400;
  line-height: ${toRem(17)};
  color: ${GLOBAL_COLOR.GRAY_900};
`;
