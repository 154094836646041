export const TOAST_MESSAGE = {
  HOSPITAL_CREATE_SCRAP: '스크랩 되었어요',
  HOSPITAL_DELETE_SCRAP: '스크랩이 해제되었어요',
  ADDRESS_CHANGE: '지역이 변경되었어요',
  SEARCH_EMPTY: '검색어를 입력해 주세요',
  COPY_LINK: 'URL이 복사되었어요',
  NETWORK_ERR_COL1: '네트워크 장애가 발생했어요',
  NETWORK_ERR_COL2: '오류가 지속된다면 고객센터에 문의해 주세요',
  CANCEL_RESERVATION: '예약이 취소되었어요',
  UPDATE_RESERVATION: '예약 내용이 변경되었어요',
  DELETED_PICTURE: '사진이 삭제되었어요',
  SELECT_THREE_CHOICES: '최대 3개까지 선택 가능해요',
  UPLOAD_5MB_CAPACITY: '용량은 최대 5mb까지 업로드 가능해요',
  UPLOAD_10MB_CAPACITY: '용량은 최대 10mb까지 업로드 가능해요',
  AVAILABLE_IMAGE_EXTENSION: 'PNG, JPG, JPEG 형식만 등록 가능해요',
  FAIL_UPLOAD_HEIF: 'HEIC, HEIF 형식은 등록 불가해요',
  UPLOADED_IMAGE: '사진이 등록되었어요',
  FAILED_UPLOAD_IMAGES: '사진 업로드에 실패했어요',
  UPLOAD_MAXIMUM_IMAGES: '사진은 최대 5장까지 등록 가능해요',
  FAILED_UPLOAD_IMAGE: '리뷰 사진 업로드에 실패했어요',
  NO_FOUND_SYMPTOM: '정확히 일치하는 #증상 또는 #질환이 없어요',
  SAME_WITH_CURRENT_VALUE: '리뷰 본문을 검색한 결과예요',
  CURRENTLY_SEARCHED_SYMPTOM_DISEASE: '현재 검색된 증상/질환이에요',
  COPY_ADDRESS: '주소가 복사되었어요',
  RECHECK_REVIEW_CONTENT: '글자 수를 다시 확인해주세요',
  FAILED_UPLOAD_ZERO_BYTE_COL1: '해당 사진은 사용할 수 없어요',
  FAILED_UPLOAD_ZERO_BYTE_COL2: '다른 사진으로 다시 시도해주세요',
  ADD_SYMPTOM: '증상을 추가해주세요',
  ADD_DISEASE: '질환을 추가해주세요',
  REQUEST_HOSPITAL: '병원 입점 요청이 완료되었어요',
  SIMILAR_SEARCH_RESULTS: '유사한 내용의 검색 결과예요',
  CORRECT_SEARCH_KEYWORD: '올바른 검색어를 입력해 주세요',
  FAIL_LOAD_LATEST_RESERVATION_INFORMATION: '병원의 예약 정보가 변경되어 불러올 수 없어요',
  RESERVATION_TIME_IS_CLOSED: '선택한 예약 시간이 마감되었어요',
  SELECT_RESERVATION_DATE_AND_TIME: '날짜와 시간을 다시 선택해 주세요',
  RESELECT_SOME_FIELDS: '항목을 다시 선택해 주세요',
};

export const SNACKBAR_MESSAGE = {
  HOSPITAL_CREATE_SCRAP: '스크랩 되었어요',
  HOSPITAL_DELETE_SCRAP: '스크랩이 해제되었어요',
};

export const ERROR_MESSAGE = {
  TEMPORARY_ERROR: '일시적인 오류가 발생했어요',
  COPY_CALL: '전화번호 정보가 없어요',
  CREATE_QNA_EMAIL_VALID: '이메일 주소를 확인해 주세요',
  CREATE_QNA_PHONE_VALID: '휴대폰 번호를 확인해 주세요',
  CREATE_QNA: '질문 작성 중 오류가 발생했어요 관리자에게 문의 해주세요',
  CREATE_QNA_CONTENT_VALID: '질문을 확인해주세요',
};
