import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SkeletonBase from './SkeletonBase';
import { Banner } from '@/api/banner';
import { ROUTE_QUERY_KEYS, ROUTES } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHospital from '@/hooks/useEventTrackerHospital';
import useNativeBridges from '@/hooks/useNativeBridges';
import { GLOBAL_COLOR } from '@/styles/colors';
import { GLOBAL_LAYOUT_PX } from '@/styles/layout';
import { Z_INDEX } from '@/styles/zIndex';
import { getImageFullUrl, replaceBrokenThumbnail, toRem } from '@/utils/commonUtils';

interface EventBannerProps {
  isShowHospitalTab: boolean;
  bannerList?: Banner[] | undefined;
  isBannerListLoading: boolean;
}

const EVENT_VERSION = 'v3';
const SECTION_TITLE = '홈 메인배너';
const SLIDER_SETTINGS = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  centerPadding: '0px',
};

const EventBanner = ({ isShowHospitalTab, bannerList, isBannerListLoading }: EventBannerProps) => {
  const router = useAppRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isFinalSlideShowed, setIsFinalSlideShowed] = useState(false);
  const { openNativePopup, isAndroid, openWebview } = useNativeBridges();
  const { trackingClickHospitalReservationHomeBanner, trackingViewHospitalReservationHomeBanner } =
    useEventTrackerHospital();
  const isBannerList = bannerList && bannerList.length > 0;

  const handleBannerClick = (banner: Banner, index: number) => {
    // TODO: 현재 배너 클릭 시, 특정 URL일 경우에 대한 예외 처리를 하나하나 하고 있는데 추후에 다른 방식으로 코드 정리가 필요함
    // event/가 아닌 review/intro 오는 경우 등 다른 형식일 경우 예외처리
    const id = banner.link.split('event/')[1];
    const hospitalId = banner.link.split('hospitals/')[1];

    if (hospitalId) {
      router.push({
        pathname: ROUTES.HOSPITALS.DETAIL,
        query: {
          [ROUTE_QUERY_KEYS.HOSPITAL_ID]: Number(hospitalId),
          [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
          [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
        },
      });
    } else if (banner.link === ROUTES.EVENT.QNA) {
      router.push(ROUTES.EVENT.QNA);
    } else if (id) {
      router.push({ pathname: ROUTES.EVENT.DETAIL, query: { [ROUTE_QUERY_KEYS.EVENT_ID]: Number(id) } });
    } else if (isAndroid()) {
      openWebview(banner.link);
    } else {
      openNativePopup(banner.link);
    }
    trackingClickHospitalReservationHomeBanner({
      bannerIndex: index,
      bannerItemId: banner.id,
      bannerItemName: banner.name ?? 'empty_string',
      eventVersion: EVENT_VERSION,
      sectionTitle: SECTION_TITLE,
    });
  };

  useEffect(() => {
    if (isBannerList && containerRef && containerRef.current) {
      const totalOffsetHeight = containerRef.current.offsetHeight + 12;

      if (isShowHospitalTab && window.scrollY < totalOffsetHeight && !isFinalSlideShowed) {
        if (currentIndex === bannerList?.length) {
          setIsFinalSlideShowed(true);
        }

        trackingViewHospitalReservationHomeBanner({
          bannerIndex: currentIndex - 1,
          bannerItemId: bannerList[currentIndex - 1].id,
          bannerItemName: bannerList[currentIndex - 1].name ?? 'empty_string',
          eventVersion: EVENT_VERSION,
          sectionTitle: SECTION_TITLE,
        });
      }
    }
  }, [
    isBannerList,
    bannerList,
    currentIndex,
    trackingViewHospitalReservationHomeBanner,
    isShowHospitalTab,
    isFinalSlideShowed,
  ]);

  return (
    <Container ref={containerRef}>
      {isBannerListLoading ? (
        <SkeletonBaseContainer>
          <StyledSkeletonBase width={'100%'} height={'100%'} borderRadius={8} />
        </SkeletonBaseContainer>
      ) : (
        <Slider {...SLIDER_SETTINGS} afterChange={(current) => setCurrentIndex(current + 1)}>
          {isBannerList &&
            bannerList?.map((item, index) => (
              <BannerImageContainer key={`banner_${index}`} onClick={() => handleBannerClick(item, index)}>
                <BannerImage
                  src={getImageFullUrl(item.image)}
                  alt={`banner_img_${index}`}
                  onError={replaceBrokenThumbnail}
                />
              </BannerImageContainer>
            ))}
        </Slider>
      )}
      {!isBannerListLoading && isBannerList && (
        <IndexContainer>
          <IndexText>{`${currentIndex} / ${bannerList?.length ?? 0}`}</IndexText>
        </IndexContainer>
      )}
    </Container>
  );
};

export default EventBanner;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: ${toRem(80)};
  position: relative;
  margin: ${toRem(12)} 0 ${toRem(16)} 0;

  @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
    height: ${toRem(100)};
  }

  .slick-slider {
    width: 100%;
    height: 100%;
  }

  .slick-slide {
    padding: 0 ${toRem(16)};
  }
`;

const SkeletonBaseContainer = styled.div`
  width: 100%;
  font-size: 0;
  margin-left: ${toRem(16)};
  margin-right: ${toRem(16)};
`;

const StyledSkeletonBase = styled(SkeletonBase)`
  height: 100%;
`;

const BannerImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${toRem(80)};
  border-radius: ${toRem(8)};

  @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
    height: ${toRem(100)};
  }
`;

const BannerImage = styled.img`
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${toRem(8)};
`;

const IndexContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: ${toRem(8)};
  right: ${toRem(24)};
  background-color: rgba(0, 0, 0, 0.4);
  padding: ${toRem(3)} ${toRem(6)};
  border-radius: ${toRem(20)};
  z-index: ${Z_INDEX.BANNER_SPINNER};
`;

const IndexText = styled.span`
  font-size: ${toRem(10)};
  font-weight: 400;
  line-height: ${toRem(12)};
  color: ${GLOBAL_COLOR.WHITE};
`;
