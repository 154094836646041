import { useQuery } from 'react-query';
import { BannerApi, BannerParams } from '@/api/banner';
import { QUERY_KEYS } from '@/constants/queryKey';

export const useGetBannerList = (params: BannerParams) => {
  const result = useQuery(
    QUERY_KEYS.BANNER.LIST(params),
    async () => {
      const response = BannerApi.getBannerList(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetBannerDetail = (id: number) => {
  const result = useQuery(
    QUERY_KEYS.BANNER.DETAIL(id),
    async () => {
      const response = BannerApi.getBannerDetail(id);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};
