import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Banner } from '@/api/banner';
import useIsInViewPort from '@/hooks/useIsInViewPort';
import { toRem } from '@/utils/commonUtils';

interface MiddleEventBannerProps {
  isShowHospitalTab: boolean;
  banner: Banner;
  onBannerClick: (banner: Banner) => void;
  onBannerView: (banner: Banner) => void;
}

const MiddleEventBanner = ({ isShowHospitalTab, banner, onBannerClick, onBannerView }: MiddleEventBannerProps) => {
  const middleBannerRef = useRef<HTMLDivElement>(null);
  const [isViewed, setIsViewed] = useState(false);

  const isInViewPort = useIsInViewPort(middleBannerRef);

  const handleBannerClick = useCallback(() => {
    if (!banner?.id) {
      return;
    }
    onBannerClick(banner);
  }, [banner, onBannerClick]);

  useEffect(() => {
    if (!isShowHospitalTab || !isInViewPort || isViewed || !banner?.id) {
      return;
    }
    setIsViewed(true);
    onBannerView(banner);
  }, [banner, isInViewPort, isShowHospitalTab, isViewed, onBannerView]);

  if (!banner || !banner.id) {
    return null;
  }

  return (
    <Container ref={middleBannerRef}>
      <BannerImageContainer key={`${banner.id}-${banner.name}`} onClick={handleBannerClick}>
        <BannerImage src={banner.image} alt={banner.name} />
      </BannerImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: ${toRem(8)} 0 ${toRem(8)} 0;
`;

const BannerImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: ${toRem(8)};
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${toRem(8)};
`;

export default React.memo(MiddleEventBanner);
