import styled from '@emotion/styled';
import React, { CSSProperties, forwardRef, ReactNode, useEffect, useState } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { Position } from '@/constants/common';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

interface BorderWith {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface TooltipProps {
  text: string;
  showTooltip: boolean;
  style?: CSSProperties;
  backgroundColor?: string;
  onCloseClick?: () => void;
  tooltipPosition?: Position;
  arrowPosition?: Position;
  arrowBorderWith?: BorderWith;
  children: ReactNode;
  isArrowReverse?: boolean;
  isHeaderTooltip?: boolean;
  isFadeOutEnabled?: boolean;
  isModalHeaderTooltip?: boolean;
  onFadeOutFinish?: () => void;
  isRiseUpTooltip?: boolean;
  riseUpTime?: number;
}

interface ContainerProps {
  backgroundColor: string;
  tooltipPosition?: Position;
  arrowPosition?: Position;
  arrowBorderWith?: BorderWith;
  isArrowReverse?: boolean;
  isHeaderTooltip?: boolean;
  isDisappeared?: boolean;
  isModalHeaderTooltip?: boolean;
  isRiseUpTooltip?: boolean;
  riseUpTime?: number;
  hasCloseButton?: boolean;
}

const FADE_OUT_DELAY_TIME_MS = 3000;
const FADE_OUT_RUNNING_TIME_MS = 300;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      text,
      showTooltip,
      style,
      backgroundColor = GLOBAL_COLOR.GRAY_800,
      onCloseClick,
      tooltipPosition,
      arrowPosition,
      arrowBorderWith,
      children,
      isArrowReverse = false,
      isHeaderTooltip = false,
      isFadeOutEnabled = false,
      isModalHeaderTooltip = false,
      onFadeOutFinish,
      isRiseUpTooltip = false,
      riseUpTime,
    },
    ref,
  ) => {
    const [isDisappeared, setIsDisappeared] = useState(false);

    useEffect(() => {
      if (isFadeOutEnabled) {
        setTimeout(() => {
          setIsDisappeared(true);
          setTimeout(() => {
            onFadeOutFinish?.();
          }, FADE_OUT_RUNNING_TIME_MS);
        }, FADE_OUT_DELAY_TIME_MS);
      }
    }, [isFadeOutEnabled, onFadeOutFinish]);

    return (
      <TooltipContainer ref={ref}>
        {children}
        {showTooltip && (
          <TooltipContent
            style={style}
            backgroundColor={backgroundColor}
            tooltipPosition={tooltipPosition}
            arrowPosition={arrowPosition}
            arrowBorderWith={arrowBorderWith}
            isArrowReverse={isArrowReverse}
            isHeaderTooltip={isHeaderTooltip}
            isModalHeaderTooltip={isModalHeaderTooltip}
            isDisappeared={isDisappeared}
            isRiseUpTooltip={isRiseUpTooltip}
            riseUpTime={riseUpTime}
            hasCloseButton={!!onCloseClick}>
            <span>{text}</span>
            {onCloseClick && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseClick();
                }}>
                <FlatIcon iconType={'icClose'} size={16} color={GLOBAL_COLOR.WHITE} />
              </div>
            )}
          </TooltipContent>
        )}
      </TooltipContainer>
    );
  },
);

export default Tooltip;

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
`;

const TooltipContent = styled.div<ContainerProps>`
  display: flex;
  position: absolute;
  align-items: center;
  padding: ${toRem(8)} ${toRem(12)};
  padding-right: ${({ hasCloseButton }) => (hasCloseButton ? toRem(8) : toRem(12))};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${toRem(8)};
  width: max-content;
  z-index: ${({ isHeaderTooltip, isModalHeaderTooltip }) => {
    if (isModalHeaderTooltip) {
      return `${Z_INDEX.MODAL_TOOLTIP}`;
    }
    return isHeaderTooltip ? `${Z_INDEX.HEADER_TOOLTIP}` : `${Z_INDEX.TOOLTIP}`;
  }};
  top: ${({ tooltipPosition }) => (tooltipPosition ? tooltipPosition.top : undefined)};
  right: ${({ tooltipPosition }) => (tooltipPosition ? tooltipPosition.right : undefined)};
  bottom: ${({ tooltipPosition }) => (tooltipPosition ? tooltipPosition.bottom : undefined)};
  left: ${({ tooltipPosition }) => (tooltipPosition ? tooltipPosition.left : undefined)};
  animation: ${({ isDisappeared, isRiseUpTooltip, riseUpTime }) => {
    if (isRiseUpTooltip) {
      return `riseUp ${riseUpTime}ms`;
    }

    return isDisappeared && `fadeout ${FADE_OUT_RUNNING_TIME_MS / 1000}s`;
  }};
  animation-fill-mode: forwards;

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes riseUp {
    0% {
      opacity: 0;
    }
    7% {
      opacity: 0;
      transform: translateY(0px);
    }
    17% {
      opacity: 1;
      transform: translateY(-${toRem(15)});
    }
    20% {
      transform: translateY(-${toRem(13)});
    }
    90% {
      transform: translateY(-${toRem(13)});
    }
    93% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(0px);
    }
  }

  filter: drop-shadow(${toRem(2)} ${toRem(2)} ${toRem(5)} rgba(129, 129, 129, 0.2));

  div {
    display: flex;
    margin-left: ${toRem(8)};
    cursor: pointer;
  }
  span {
    font-weight: 500;
    font-size: ${toRem(14)};
    line-height: ${toRem(20)};
    color: ${GLOBAL_COLOR.WHITE};
  }
  ::after {
    border-color: ${({ backgroundColor }) => backgroundColor} transparent;
    border-style: solid;
    border-width: ${({ arrowBorderWith }) => {
      const { top = '0', right = `${toRem(7)}`, bottom = `${toRem(9)}`, left = `${toRem(7)}` } = arrowBorderWith || {};
      return `${top} ${right} ${bottom} ${left}`;
    }};
    content: '';
    display: block;
    position: absolute;
    top: ${({ arrowPosition }) => (arrowPosition ? arrowPosition.top : `${toRem(-8)}`)};
    right: ${({ arrowPosition }) => (arrowPosition ? arrowPosition.right : `${toRem(10)}`)};
    bottom: ${({ arrowPosition }) => (arrowPosition ? arrowPosition.bottom : undefined)};
    left: ${({ arrowPosition }) => (arrowPosition ? arrowPosition.left : undefined)};
    width: 0;
    z-index: ${Z_INDEX.TOOLTIP};
    transform: ${({ isArrowReverse }) => (isArrowReverse ? 'rotate(0.5turn)' : 'none')};
  }
`;
