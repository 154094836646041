import { useCallback } from 'react';
import { Hospital, HospitalCurationType } from '@/api/hospital';
import { BASIC_EVENT_TEXT } from '@/constants/common';
import { Sort } from '@/constants/filter';
import { PROMOTION_EVENT_HOSPITAL_LIST } from '@/constants/policy';
import { ROUTES, ROUTE_QUERY_KEYS } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHospitalCuration from '@/hooks/useEventTrackerHospitalCuration';
import {
  checkIsIntolinkHospital,
  getHospitalLabels,
  getParsedTotalScore,
  getPromotionEventHospital,
} from '@/utils/commonUtils';
import { convertUpperCaseCurationType } from '@/utils/stringUtils';
import { getCurationTabName } from '@/utils/tracker/trackerUtils';

interface UseHospitalCurationDetailProps {
  sort: Sort;
  isReservationPossibleValue: boolean;
  isWorkingValue: boolean;
  addressChipName: string;
}

export const CURATION_LIST = [
  {
    type: convertUpperCaseCurationType(HospitalCurationType.NEW),
    title: '신규입점',
    imageNormalText: '',
    imageBoldText: '',
    imageUrl: '',
  },
  {
    type: convertUpperCaseCurationType(HospitalCurationType.ALWAYS_VISIT),
    title: '언제든지',
    imageNormalText: '아프면 언제든!',
    imageBoldText: '시간 제약 없이 방문하세요',
    imageUrl: '/images/curation_anytime.png',
  },
  {
    tagId: 5,
    type: convertUpperCaseCurationType(HospitalCurationType.CAT_FRIENDLY),
    title: '고양이병원',
    imageNormalText: '냥쌤들을 만나려면',
    imageBoldText: '고양이 친화 병원',
    imageUrl: '/images/curation_cat_hospital.png',
  },
  {
    tagId: 14,
    type: convertUpperCaseCurationType(HospitalCurationType.NEUTRALIZATION),
    title: '중성화수술',
    imageNormalText: '아직 안 했다면',
    imageBoldText: '중성화 수술 여기서 할 수 있어요',
    imageUrl: '/images/curation_neutering.png',
  },
  {
    tagId: 94,
    type: convertUpperCaseCurationType(HospitalCurationType.HEALTH),
    title: '건강검진',
    imageNormalText: '정기적으로 받고있나요?',
    imageBoldText: '아픈 곳은 없는지 검진 받으세요',
    imageUrl: '/images/curation_health_exam.png',
  },
  {
    tagId: 50,
    type: convertUpperCaseCurationType(HospitalCurationType.DENTAL),
    title: '치과진료',
    imageNormalText: '스케일링 받으러 가나요?',
    imageBoldText: '치과 진료 병원',
    imageUrl: '/images/curation_dental_clinic.png',
  },
  {
    tagId: 10,
    type: convertUpperCaseCurationType(HospitalCurationType.DOG_GROOMING),
    title: '강아지미용',
    imageNormalText: '병원 간 김에 미용까지',
    imageBoldText: '저희 병원은 강아지 미용도 해요',
    imageUrl: '/images/curation_dog_beauty.png',
  },
  {
    tagId: 18,
    type: convertUpperCaseCurationType(HospitalCurationType.SKIN_CARE),
    title: '피부치료',
    imageNormalText: '흉터 하나 안 남도록 케어해요',
    imageBoldText: '피부 치료도 잘 하는 곳',
    imageUrl: '/images/curation_skin_clinic.png',
  },
  {
    tagId: 15,
    type: convertUpperCaseCurationType(HospitalCurationType.PATELLA_LUXATION),
    title: '슬개골탈구',
    imageNormalText: '관절 영양제는 꼬박꼬박 챙겨주세요',
    imageBoldText: '슬개골 탈구 치료 병원',
    imageUrl: '/images/curation_knee_illness.png',
  },
  {
    tagId: 109,
    type: convertUpperCaseCurationType(HospitalCurationType.CARDIOLOGIST),
    title: '심장전문',
    imageNormalText: '콩닥콩닥',
    imageBoldText: '심장 질환이 있나요?',
    imageUrl: '/images/curation_heart_clinic.png',
  },
];

const useHospitalCurationDetail = ({
  sort,
  isReservationPossibleValue,
  isWorkingValue,
  addressChipName,
}: UseHospitalCurationDetailProps) => {
  const router = useAppRouter();
  const { allDay, weekEnd, overNight, curationType } = router.query;
  const curation = CURATION_LIST.find((item) => item.type === curationType);
  const { trackingClickHospitalReservationCurationListHospital } = useEventTrackerHospitalCuration();

  const handleScrollTop = useCallback(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handleLeftIconClick = useCallback(() => {
    router.back();
  }, [router]);

  const handleAddressClick = useCallback(
    ({ code }: { code?: string }) => {
      handleScrollTop();
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          [ROUTE_QUERY_KEYS.CURATION_TYPE]: curationType,
          [ROUTE_QUERY_KEYS.ADDRESS_CODE]: code,
        },
      });
    },
    [handleScrollTop, router, curationType],
  );

  const handleHospitalCardClick = useCallback(
    (hospital: Hospital) => {
      const promotionEventHospital = getPromotionEventHospital({
        hospitalList: PROMOTION_EVENT_HOSPITAL_LIST,
        hospitalId: hospital.id,
      });
      const labelNames = getHospitalLabels({
        isIntolinkHospital: checkIsIntolinkHospital(hospital.intoLinkStatus),
        isReservationPossible: hospital.isReservationPossible,
        isAlliance: hospital.isAlliance,
        isPromotion: promotionEventHospital?.isPromotion || false,
      });
      const bannerText = hospital.isReservationPossible && !promotionEventHospital ? BASIC_EVENT_TEXT : '';
      const curationTabName = getCurationTabName({ allDay, weekEnd, overNight });

      trackingClickHospitalReservationCurationListHospital({
        locationId: hospital.id,
        locationName: hospital.name,
        distance: hospital.distance,
        tagNames: hospital.expertise || [],
        hospitalReviewTotalCount: hospital.reviewCount,
        hospitalReviewAvgScore: Number(getParsedTotalScore(hospital.totalScore)),
        labelNames,
        bannerText,
        curationName: curation?.title || '',
        tabName: curationTabName,
        chipName: addressChipName,
        isOpenChecked: isWorkingValue,
        isReservationChecked: isReservationPossibleValue,
        sortBy: sort.label,
        pathPage: router.query.pathPage,
      });

      router.push({
        pathname: ROUTES.HOSPITALS.DETAIL,
        query: {
          [ROUTE_QUERY_KEYS.HOSPITAL_ID]: hospital.id,
          [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
          [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
          [ROUTE_QUERY_KEYS.CURATION_TYPE]: curation?.type,
        },
      });
    },
    [
      addressChipName,
      allDay,
      curation?.title,
      isReservationPossibleValue,
      isWorkingValue,
      overNight,
      router,
      sort.label,
      trackingClickHospitalReservationCurationListHospital,
      weekEnd,
      curation?.type,
    ],
  );

  return { curation, handleScrollTop, handleLeftIconClick, handleAddressClick, handleHospitalCardClick };
};

export default useHospitalCurationDetail;
