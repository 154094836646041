import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import Tooltip from '@/components/common/Tooltip';
import BaseHeaderContainer from '@/components/common/header/BaseHeaderContainer';
import { ROUTES } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHome from '@/hooks/useEventTrackerHome';
import { useLoginModal } from '@/hooks/useLoginModal';
import { GLOBAL_COLOR } from '@/styles/colors';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { toRem } from '@/utils/commonUtils';
import WebStorage from '@/utils/webStorage';

interface HomeHeaderProps {
  isReservationUpdated?: boolean;
  onSetShowHospitalTab?: () => void;
}

interface NavigationItem {
  title: string;
  pathname: string;
  tooltipTypes?: { text: string; isShowTooltip: boolean; onCloseClick?: () => void };
}

const HomeHeader = ({ isReservationUpdated, onSetShowHospitalTab }: HomeHeaderProps) => {
  const router = useAppRouter();
  const loginModalProps = useLoginModal();
  const { isLogin } = useCheckLogin();
  const [isShowReservationTooltip, setIsShowReservationTooltip] = useState(false);
  const {
    trackingClickHospitalCommonTopMenuTab,
    trackingClickHospitalCommonReservationIcon,
    trackingClickHospitalTabSearch,
  } = useEventTrackerHome();
  const isHospitalTab = useMemo(() => router.isCurrentPathname(ROUTES.HOME), [router]);

  const navigationItems: NavigationItem[] = [
    {
      title: '병원',
      pathname: ROUTES.HOME,
    },
    {
      title: '리뷰',
      pathname: ROUTES.REVIEWS.LIST,
      tooltipTypes: {
        text: '궁금한 증상/질환이 있나요?',
        isShowTooltip: isShowReservationTooltip,
        onCloseClick: () => {
          setIsShowReservationTooltip(false);
          WebStorage.setIsShowReservationTooltip(false);
        },
      },
    },
  ];

  const handleNavigationItemClick = useCallback(
    (naviItem: NavigationItem) => {
      trackingClickHospitalCommonTopMenuTab({ hospitalTopMenuName: naviItem.title });
      router.replace(naviItem.pathname);
    },
    [router, trackingClickHospitalCommonTopMenuTab],
  );

  const handleSearchIconClick = useCallback(() => {
    trackingClickHospitalTabSearch();
    router.push(ROUTES.SEARCH.HOSPITAL);

    if (onSetShowHospitalTab) {
      onSetShowHospitalTab();
    }
  }, [onSetShowHospitalTab, router, trackingClickHospitalTabSearch]);

  const handleCalendarIconClick = useCallback(() => {
    if (!isLogin()) {
      loginModalProps.showModalWithCallback({ onLogin: handleCalendarIconClick });
      return;
    }

    trackingClickHospitalCommonReservationIcon();
    router.push(ROUTES.RESERVATIONS.LIST);

    if (onSetShowHospitalTab) {
      onSetShowHospitalTab();
    }
  }, [isLogin, loginModalProps, onSetShowHospitalTab, router, trackingClickHospitalCommonReservationIcon]);

  useEffect(() => {
    const isShowReservationTooltipValue = WebStorage.isShowReservationTooltip();

    if (isShowReservationTooltipValue) {
      setIsShowReservationTooltip(true);
    }
  }, []);

  return (
    <BaseHeaderContainer position={'fixed'}>
      <NavigationContainer>
        {navigationItems.map((item, index) => {
          const reactKey = `${index}-${item.pathname}`;
          const isActive = router.isCurrentPathname(item.pathname);
          const NavigationContent = () => {
            return (
              <NavigationItemContainer
                isActive={isActive}
                marginLeft={index === 0 ? 16 : 0}
                paddingLeft={index === 0 ? 4 : 8}
                paddingRight={8}
                onClick={() => handleNavigationItemClick(item)}>
                <TitleContainer>
                  {item.title}
                  {isActive && (
                    <ActiveIconContainer>
                      <FlatIcon iconType={'icDot'} size={4} color={GLOBAL_COLOR.GRAY_900} />
                    </ActiveIconContainer>
                  )}
                </TitleContainer>
              </NavigationItemContainer>
            );
          };

          if (!item.tooltipTypes) {
            return <NavigationContent key={reactKey} />;
          }

          return (
            <Tooltip
              key={reactKey}
              showTooltip={item.tooltipTypes.isShowTooltip}
              text={item.tooltipTypes.text}
              tooltipPosition={{ top: `${toRem(42)}` }}
              arrowPosition={{ top: `${toRem(-7)}`, left: `${toRem(18.5)}` }}
              onCloseClick={item.tooltipTypes.onCloseClick}>
              <NavigationContent />
            </Tooltip>
          );
        })}
      </NavigationContainer>
      <RightIconContainer>
        {isHospitalTab && (
          <SearchIconContainer onClick={handleSearchIconClick}>
            <IconContainer>
              <FlatIcon iconType={'icSearch'} size={24} color={GLOBAL_COLOR.GRAY_900} />
            </IconContainer>
          </SearchIconContainer>
        )}
        <CalendarIconContainer onClick={handleCalendarIconClick}>
          <IconContainer>
            <FlatIcon iconType={'icCalendar'} size={24} color={GLOBAL_COLOR.GRAY_900} />
          </IconContainer>
          {isReservationUpdated && (
            <DotIconContainer>
              <FlatIcon iconType={'icDot'} size={4} color={GLOBAL_COLOR.RED_500} />
            </DotIconContainer>
          )}
        </CalendarIconContainer>
      </RightIconContainer>
    </BaseHeaderContainer>
  );
};

export default HomeHeader;

const NavigationContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  left: 0;
  height: 100%;
`;

const NavigationItemContainer = styled.div<{
  isActive: boolean;
  marginLeft: number;
  paddingLeft: number;
  paddingRight: number;
}>`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: ${({ marginLeft }) => `${toRem(marginLeft)}`};
  padding-left: ${({ paddingLeft }) => `${toRem(paddingLeft)}`};
  padding-right: ${({ paddingRight }) => `${toRem(paddingRight)}`};
  color: ${({ isActive }) => (isActive ? `${GLOBAL_COLOR.GRAY_900}` : `${GLOBAL_COLOR.GRAY_300}`)};
  font-weight: 700;
  font-size: ${toRem(20)};
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
`;

const ActiveIconContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: start;
  top: 100%;
  transform: translate(0, -50%);
  margin: 0;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;

  i {
    justify-content: center;
  }
`;

const RightIconContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  right: 0;
  height: 100%;
  margin-left: ${toRem(8)};
`;

const SearchIconContainer = styled.div`
  position: relative;
  margin-right: ${toRem(20)};
`;

const CalendarIconContainer = styled.div`
  position: relative;
  margin-right: ${toRem(18)};
`;

const DotIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -100%);
`;

const IconContainer = styled.div`
  position: relative;
`;
