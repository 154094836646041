import styled from '@emotion/styled';
import SkeletonBase from '@/components/common/SkeletonBase';
import { toRem } from '@/utils/commonUtils';

const HospitalCurationListSkeleton = () => {
  return (
    <Container>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <SkeletonBaseContainer key={item}>
          <SkeletonBase width={48} height={48} borderRadius={12} />
          <TextSkeleton>
            <SkeletonBase width={48} height={14} borderRadius={6} />
          </TextSkeleton>
        </SkeletonBaseContainer>
      ))}
    </Container>
  );
};

export default HospitalCurationListSkeleton;

const Container = styled.div`
  display: flex;
  column-gap: ${toRem(8)};
  padding-left: ${toRem(16)};
  padding-right: ${toRem(16)};
  font-size: 0;
  height: ${toRem(69)};
  overflow-y: hidden;
`;

const SkeletonBaseContainer = styled.div`
  padding-left: ${toRem(4)};
  padding-right: ${toRem(4)};
`;

const TextSkeleton = styled.div`
  margin-top: ${toRem(4)};
`;
