import { getQueryParams } from '@/api/apiUtils';
import { BASE_URL, HTTP } from '@/api/http';

export enum BANNER_TARGET {
  BLANK = '_blank',
  SELF = '_self',
}

export enum BANNER_TYPE {
  MAIN = 'Main',
  HOSPITAL_DETAIL = 'HospitalDetail',
  REVIEW_WRITE = 'ReviewWrite',
  REVIEW_SUCCESS = 'ReviewSuccess',
  MIDDLE_BANNER = 'MiddleBanner',
}

export interface Banner {
  id: number;
  image: string;
  link: string;
  target: BANNER_TARGET;
  type: BANNER_TYPE;
  order: number;
  name?: string;
  hospitalId?: number;
  isAlwaysShowing: boolean;
}

export interface BannerParams {
  isDeleted?: boolean;
  type?: BANNER_TYPE;
  startAt?: string;
  endAt?: string;
}

export interface EventDetailResponse {
  id: number;
  title: string;
  order: number;
  startAt: string;
  endAt: string;
  hospitalEventDescriptions: HospitalEventDescriptions[];
}

export interface HospitalEventDescriptions {
  id: number;
  startAt: string;
  endAt: string;
  mediaType: number;
  image: string;
  order: number;
  link: string;
  target: boolean;
}

export const BannerApi = {
  getBannerList: async (params: BannerParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<Banner[]>(`${BASE_URL}/hospitals/banners?${queryParams}`);
    return response.data;
  },
  getBannerDetail: async (id: number) => {
    const response = await HTTP.get<EventDetailResponse>(`${BASE_URL}/hospitals/events/${id}`);
    return response.data;
  },
};
