import styled from '@emotion/styled';
import FlatIcon from '@/components/common/FlatIcon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface HospitalTabAddressCardProps {
  addressData: string;
  onChangeButtonClick: () => void;
  className?: string;
}

const HospitalTabAddressCard = ({ addressData, onChangeButtonClick, className }: HospitalTabAddressCardProps) => {
  return (
    <Container onClick={onChangeButtonClick} className={className}>
      {addressData && (
        <AddressContainer>
          <AddressText>{addressData}</AddressText>
          <FlatIcon iconType={'icChevronDown'} color={GLOBAL_COLOR.GRAY_900} size={24} />
        </AddressContainer>
      )}
    </Container>
  );
};

export default HospitalTabAddressCard;

const Container = styled.div`
  display: flex;
  background-color: ${GLOBAL_COLOR.WHITE};
  margin-top: ${toRem(20)};
  margin-bottom: ${toRem(12)};
  margin-left: ${toRem(16)};
  margin-right: ${toRem(16)};
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddressText = styled.span`
  font-size: ${toRem(20)};
  font-weight: 700;
  line-height: ${toRem(28)};
  color: ${GLOBAL_COLOR.GRAY_900};
  margin-right: ${toRem(4)};
`;
