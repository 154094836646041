import styled from '@emotion/styled';
import { useCallback } from 'react';
import HospitalQuickMenuCurationList from './HospitalQuickMenuCurationList';
import { CURATION_LIST } from './useHospitalCurationDetail';
import { HospitalCuration, HospitalCurationType, HospitalCurationTypeText } from '@/api/hospital';
import { SORT_LIST_KEY } from '@/constants/filter';
import { ROUTES, ROUTE_QUERY_KEYS } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHospitalCuration from '@/hooks/useEventTrackerHospitalCuration';
import { GLOBAL_COLOR } from '@/styles/colors';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { getImageFullUrl, replaceBrokenThumbnail, toRem } from '@/utils/commonUtils';
import { convertUpperCaseCurationType } from '@/utils/stringUtils';
import WebStorage from '@/utils/webStorage';

interface HospitalCurationListProps {
  filteredHospitalCurationData: HospitalCuration[];
  curationTotalNames: string[];
  curationTotalCount: number;
}

const HospitalCurationList = ({
  filteredHospitalCurationData,
  curationTotalNames,
  curationTotalCount,
}: HospitalCurationListProps) => {
  const router = useAppRouter();
  const { trackingClickHospitalReservationCurationItem } = useEventTrackerHospitalCuration();
  const { isLogin } = useCheckLogin();
  const isLoggedIn = isLogin();

  const handleHospitalCurationClick = useCallback(
    (type: keyof typeof HospitalCurationType, index: number) => {
      const QUICK_MENU_LIST_COUNT = 2; // 기존 퀵메뉴: 최근방문, 스크랩병원
      const listIndex = isLoggedIn ? index + QUICK_MENU_LIST_COUNT : index;
      const convertedCurationType = convertUpperCaseCurationType(type);
      const foundCurationTitle = CURATION_LIST.find((curation) => curation.type === convertedCurationType)?.title || '';
      const isNewCurationType = convertedCurationType === convertUpperCaseCurationType(HospitalCurationType.NEW);
      const isAlwaysVisitCurationType =
        convertedCurationType === convertUpperCaseCurationType(HospitalCurationType.ALWAYS_VISIT);

      WebStorage.removePageScrollYPosition(ROUTES.HOSPITALS.CURATION_DETAIL);

      trackingClickHospitalReservationCurationItem({
        listIndex,
        curationName: foundCurationTitle,
        curationTotalNames,
        curationTotalCount,
      });

      router.push({
        pathname: ROUTES.HOSPITALS.CURATION_DETAIL,
        query: {
          [ROUTE_QUERY_KEYS.CURATION_TYPE]: convertedCurationType,
          [ROUTE_QUERY_KEYS.ADDRESS_CODE]: '',
          [ROUTE_QUERY_KEYS.SORT_BY]: SORT_LIST_KEY.RANKING,
          [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
          ...(isNewCurationType ? { isNew: true } : {}),
          ...(isAlwaysVisitCurationType ? { allDay: true } : {}),
        },
      });
    },
    [isLoggedIn, router, curationTotalNames, curationTotalCount, trackingClickHospitalReservationCurationItem],
  );

  return (
    <Container>
      <ContentWrapper>
        {isLoggedIn && (
          <HospitalQuickMenuCurationList
            filteredHospitalCurationData={filteredHospitalCurationData}
            curationTotalNames={curationTotalNames}
            curationTotalCount={curationTotalCount}
          />
        )}

        {filteredHospitalCurationData.map((item, index) => (
          <Content key={item.curationType} onClick={() => handleHospitalCurationClick(item.curationType, index)}>
            <ImageContainer>
              <Image src={getImageFullUrl(item.imageUrl)} alt={item.curationType} onError={replaceBrokenThumbnail} />
            </ImageContainer>
            <Text>{HospitalCurationTypeText[item.curationType]}</Text>
          </Content>
        ))}

        {!isLoggedIn && (
          <HospitalQuickMenuCurationList
            filteredHospitalCurationData={filteredHospitalCurationData}
            curationTotalNames={curationTotalNames}
            curationTotalCount={curationTotalCount}
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

export default HospitalCurationList;

const Container = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${toRem(8)};
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-left: ${toRem(16)};
  padding-right: ${toRem(16)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${toRem(4)};
  padding-right: ${toRem(4)};
  white-space: nowrap;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  border-radius: ${toRem(12)};
  background-color: ${GLOBAL_COLOR.GRAY_50};
  padding: ${toRem(6)} ${toRem(5.5)} ${toRem(6)} ${toRem(6.5)};
  margin-bottom: ${toRem(4)};
  font-size: 0;
`;

const Image = styled.img`
  width: ${toRem(36)};
  height: ${toRem(36)};
`;

const Text = styled.span`
  font-size: ${toRem(12)};
  font-weight: 400;
  line-height: ${toRem(17)};
  color: ${GLOBAL_COLOR.GRAY_900};
`;
