import { useCallback, useEffect } from 'react';
import { Address } from '@/api/address';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import useGeoAddress from '@/hooks/useGeoAddress';
import useGetCurrentLocation from '@/hooks/useGetCurrentLocation';
import useNativeBridges from '@/hooks/useNativeBridges';
import { isInApp } from '@/utils/deviceUtils';
import { getSessionStorageBooleanItem, setSessionStorageItem } from '@/utils/webStorage';

interface UseGetInitialLocationProps {
  setSelectedAddress: (newAddress: Address) => void;
}

const useGetInitialLocation = ({ setSelectedAddress }: UseGetInitialLocationProps) => {
  const { getLocationPermission } = useNativeBridges();
  const { addressGeoData, setLocationForRequestGeoLocation } = useGeoAddress({
    onAddressByGeoChange: setSelectedAddress,
  });

  const handleLocationChange = useCallback(
    (longitude: number, latitude: number) => {
      setLocationForRequestGeoLocation(longitude, latitude);
    },
    [setLocationForRequestGeoLocation],
  );

  const { getCurrentLocationAsync } = useGetCurrentLocation({ onLocationChange: handleLocationChange });

  const handleTabLocation = useCallback(async () => {
    const result = await getLocationPermission();

    if (result?.status) {
      setSessionStorageItem(WEB_STORAGE_KEY.IS_GET_CURRENT_LOCATION_ONCE, 'true');
    }
    getCurrentLocationAsync();
  }, [getCurrentLocationAsync, getLocationPermission]);

  useEffect(() => {
    if (!isInApp()) {
      return;
    }

    // 탭 진입시 처음 한번만 위치를 받아왔는지 확인
    const checkIsGetLocation = Boolean(getSessionStorageBooleanItem(WEB_STORAGE_KEY.IS_GET_CURRENT_LOCATION_ONCE));

    // TODO: aos 싱크를 맞추기 위한 timeout => 앱 수정 후 배포 완료시 삭제 예정
    // eslint-disable-next-line no-undef
    let mapSyncTimeout: NodeJS.Timeout;

    if (!checkIsGetLocation) {
      // eslint-disable-next-line prefer-const
      mapSyncTimeout = setTimeout(() => {
        handleTabLocation().then();
      }, 300);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (mapSyncTimeout) {
        clearTimeout(mapSyncTimeout);
      }
    };
  }, [handleTabLocation]);

  return { addressGeoData };
};

export default useGetInitialLocation;
