import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
import { SkeletonStyleProps } from 'react-loading-skeleton/dist/SkeletonStyleProps';
import { toRemIfNeed } from '@/utils/layoutUtils';

interface SkeletonPaddingProps {
  padding?: string;
}

interface SkeletonBaseProps extends SkeletonStyleProps, SkeletonPaddingProps {
  className?: string;
}

const SkeletonBase = ({ padding, width, height, className, ...props }: SkeletonBaseProps) => {
  return (
    <StyledSkeletonPaddingWrapper className={className} padding={padding}>
      <StyledSkeletonWrapper width={width} height={height}>
        <StyledSkeleton {...props} enableAnimation={true} />
      </StyledSkeletonWrapper>
    </StyledSkeletonPaddingWrapper>
  );
};

export default SkeletonBase;

const StyledSkeletonWrapper = styled.div<SkeletonBaseProps>`
  width: ${({ width }) => (width ? `${toRemIfNeed(width)}` : undefined)};
  height: ${({ height }) => (height ? `${toRemIfNeed(height)}` : undefined)};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${toRemIfNeed(borderRadius)}` : undefined)};
`;

const StyledSkeletonPaddingWrapper = styled.div<SkeletonPaddingProps>`
  padding: ${({ padding }) => padding ?? undefined};
`;

const StyledSkeleton = styled(Skeleton)<SkeletonStyleProps>`
  width: 100%;
  height: 100%;
`;
