import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { GetLiveHospitalIdResponse, HospitalDetail, INTO_LINK_STATUS } from '@/api/hospital';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import { parseToString } from '@/utils/commonUtils';
import { DATE_FORMAT } from '@/utils/dateUtils';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/utils/webStorage';

interface RecentViewHospital {
  id: number;
  name: string;
  reviewCount: number;
  totalScore: number;
  isReservationAvailable: boolean;
  addr: string;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
  startDate: string;
  endDate: string;
}

const useRecentViewHospital = () => {
  const [recentViewHospitalList, setRecentViewHospitalList] = useState<RecentViewHospital[]>([]);

  const handleGetRecentViewHospitalList = useCallback(() => {
    const recentViewHospitalItem = getLocalStorageItem(WEB_STORAGE_KEY.RECENT_VIEW_HOSPITAL);

    if (recentViewHospitalItem) {
      const parsedRecentViewHospital = JSON.parse(recentViewHospitalItem) as RecentViewHospital[];
      return parsedRecentViewHospital;
    }
    return [];
  }, []);

  const handleAddRecentViewHospital = useCallback((hospital: HospitalDetail) => {
    const newObject: RecentViewHospital = {
      id: hospital.id,
      name: hospital.name,
      reviewCount: hospital.reviewCount,
      totalScore: hospital.totalScore,
      isReservationAvailable: hospital.isReservationPossible,
      addr: `${hospital.address?.address1Shorts ?? ''} ${hospital.address?.address2 ?? ''} ${
        hospital.address?.address3 ?? ''
      }`,
      intoLinkStatus: hospital.intoLinkStatus,
      startDate: dayjs().format(DATE_FORMAT.DATE),
      endDate: dayjs().add(7, 'days').format(DATE_FORMAT.DATE),
    };
    const newArray = [newObject];

    setRecentViewHospitalList(newArray);
    setLocalStorageItem(WEB_STORAGE_KEY.RECENT_VIEW_HOSPITAL, JSON.stringify(newArray));
  }, []);

  const handleResetRecentViewHospitalList = useCallback(() => {
    setRecentViewHospitalList([]);
    removeLocalStorageItem(WEB_STORAGE_KEY.RECENT_VIEW_HOSPITAL);
  }, []);

  const handleSetRecentViewHospitalList = useCallback(
    (data: GetLiveHospitalIdResponse) => {
      const recentViewHospitalListValue = handleGetRecentViewHospitalList();
      const result = recentViewHospitalListValue.filter((item) => data.includes(item.id));

      if (isEqual(recentViewHospitalListValue, result)) {
        return;
      }

      setRecentViewHospitalList(result);
      setLocalStorageItem(WEB_STORAGE_KEY.RECENT_VIEW_HOSPITAL, parseToString(result));
    },
    [handleGetRecentViewHospitalList],
  );

  useEffect(() => {
    const recentViewHospitalItem = getLocalStorageItem(WEB_STORAGE_KEY.RECENT_VIEW_HOSPITAL);

    if (recentViewHospitalItem) {
      const parsedRecentViewHospital = JSON.parse(recentViewHospitalItem);
      setRecentViewHospitalList(parsedRecentViewHospital);
    }
  }, []);

  return {
    recentViewHospitalList,
    setRecentViewHospitalList,
    handleGetRecentViewHospitalList,
    handleAddRecentViewHospital,
    handleResetRecentViewHospitalList,
    handleSetRecentViewHospitalList,
  };
};

export default useRecentViewHospital;
