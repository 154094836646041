import { useQuery } from 'react-query';
import { HospitalApi } from '@/api/hospital';
import { QUERY_KEYS } from '@/constants/queryKey';

export const useGetHospitalDetail = (hospitalId: number) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.DETAIL(hospitalId),
    async () => {
      const response = HospitalApi.getHospitalDetail(hospitalId);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: hospitalId > 0 },
  );
  return result;
};

export const useGetHospitalCuration = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.CURATION(),
    async () => {
      const response = HospitalApi.getHospitalCuration();
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};
