import styled from '@emotion/styled';
import { DIRECTION, DIRECTION_TYPE } from '@/constants/common';
import { GLOBAL_COLOR_TYPE } from '@/styles/colors';
import { toRemIfNeed } from '@/utils/layoutUtils';

interface SeparatorProps {
  border?: string | number;
  color?: string;
  direction?: DIRECTION_TYPE;
  className?: string;
}

const Separator = ({
  border = 1,
  color = GLOBAL_COLOR_TYPE.DIVIDER_COLOR,
  direction = DIRECTION.HORIZONTAL,
  className,
}: SeparatorProps) => {
  const width = toRemIfNeed(direction === DIRECTION.VERTICAL ? border : '100%');
  const height = direction === DIRECTION.VERTICAL ? undefined : toRemIfNeed(border);
  return <StyledSeparator className={className} width={width} height={height} backgroundColor={color} />;
};

export default Separator;

const StyledSeparator = styled.div<{ width: string; height?: string; backgroundColor: string }>`
  display: block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
