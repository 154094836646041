import { ParsedUrlQuery } from 'node:querystring';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Address, ADDRESS_TYPE, AddressUtil } from '@/api/address';
import { BANNER_TYPE } from '@/api/banner';
import { Hospital } from '@/api/hospital';
import HospitalTabAddressCard from '@/components/address/HospitalTabAddressCard';
import CheckBox from '@/components/common/CheckBox';
import EmptyComponent from '@/components/common/EmptyComponent';
import EventBanner from '@/components/common/EventBanner';
import FlatIcon from '@/components/common/FlatIcon';
import InfiniteScroll from '@/components/common/InfiniteScroll';
import MiddleEventBanner from '@/components/common/MiddleEventBanner';
import Separator from '@/components/common/Separator';
import TopButton from '@/components/common/buttons/TopButton';
import HomeHeader from '@/components/common/header/HomeHeader';
import AddressFilterModal from '@/components/filter/AddressFilterModal';
import HospitalFilterModal from '@/components/filter/HospitalFilterModal';
import HospitalSubFilterContainer from '@/components/filter/HospitalSubfFilterContainer';
import HospitalFilter from '@/components/filter/HosptialFilter';
import ListSortModal from '@/components/filter/ListSortModal';
import HospitalCard from '@/components/hospital/HospitalCard';
import HospitalCardSkeleton from '@/components/hospital/HospitalCardSkeleton';
import HospitalCurationList from '@/components/hospital/HospitalCurationList';
import HospitalCurationListSkeleton from '@/components/hospital/HospitalCurationListSkeleton';
import useHospitalBanner from '@/components/hospital/useHospitalBanner';
import { CURATION_LIST } from '@/components/hospital/useHospitalCurationDetail';
import useHospitalScrap from '@/components/hospital/useHospitalScrap';
import ConfirmModal from '@/components/modal/ConfirmModal';
import { Body1 } from '@/components/typeface/Body1';
import { Body3 } from '@/components/typeface/Body3';
import { DEFAULT_NATIONWIDE_ADDRESS, BASIC_EVENT_TEXT } from '@/constants/common';
import { DeepLinkHospitalFilter, FILTER_LIST, SORT_FILTER } from '@/constants/filter';
import { PROMOTION_EVENT_HOSPITAL_LIST } from '@/constants/policy';
import { ROUTE_QUERY_KEYS, ROUTES } from '@/constants/routes';
import { TOAST_MARGIN } from '@/constants/toast';
import { TOAST_MESSAGE } from '@/constants/toastMessage';
import { useInAppBridge } from '@/context/InAppProvider';
import { useUpdatedReservationData } from '@/context/ReservationProvider';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHome from '@/hooks/useEventTrackerHome';
import useEventTrackerHospitalCuration from '@/hooks/useEventTrackerHospitalCuration';
import useEventTrackerHospitalTab from '@/hooks/useEventTrackerHospitalTab';
import useFilterResetModal from '@/hooks/useFilterResetModal';
import useGetInitialLocation from '@/hooks/useGetInitialLocation';
import { FILTER_TYPE, useHospitalFilter } from '@/hooks/useHospitalFilter';
import { useModal } from '@/hooks/useModal';
import useNativeBridges from '@/hooks/useNativeBridges';
import useRecentViewHospital from '@/hooks/useRecentViewHospital';
import useToast from '@/hooks/useToast';
import { useGetAddressByCode } from '@/queries/query/useAddressQuery';
import { useGetBannerList } from '@/queries/query/useBannerQuery';
import {
  useGetHospitalFilterList,
  useGetHospitalList,
  useGetLiveHospitalId,
  useGetReviewWriteShortcut,
} from '@/queries/query/useHospitalListQuery';
import { useGetHospitalCuration } from '@/queries/query/useHospitalQuery';
import useSelectedAddressStore from '@/store/useSelectedAddressStore';
import { GLOBAL_COLOR } from '@/styles/colors';
import { HEADER_HEIGHT } from '@/styles/sizes';
import { Z_INDEX } from '@/styles/zIndex';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import {
  checkIsIntolinkHospital,
  getHospitalLabels,
  getPromotionEventHospital,
  removeSelectedAddressToLocalStorage,
  setSelectedAddressToLocalStorage,
  toRem,
} from '@/utils/commonUtils';
import { isInApp } from '@/utils/deviceUtils';
import { convertUpperCaseCurationType } from '@/utils/stringUtils';
import WebStorage from '@/utils/webStorage';

const MIDDLE_BANNER_POSITION = 5;

const HospitalTab = () => {
  const inAppBridge = useInAppBridge();
  const { showToast } = useToast();
  const router = useAppRouter();
  const [isShowHospitalTab, setIsShowHospitalTab] = useState(true);
  const [flag, setFlag] = useState(false);
  const addressModalProps = useModal();
  const { handleGetRecentViewHospitalList, handleSetRecentViewHospitalList } = useRecentViewHospital();
  const recentViewHospitalList = handleGetRecentViewHospitalList();
  const { trackingViewHospitalHome } = useEventTrackerHome();
  const { getLocationPermission } = useNativeBridges();
  const { isLogin } = useCheckLogin();
  const isLoggedIn = isLogin();

  const {
    filterResetModalProps,
    handleApplyResetModalNotShowToday,
    isNotShowChecked,
    handleNotShowCheck,
    handleCancelNotShowCheck,
    isFilterModalExpired,
  } = useFilterResetModal();

  const {
    filterModalProps,
    sortModalProps,
    filterList,
    filterParams,
    sort,
    selectedFilterType,
    isReservationAvailable,
    isWorking,
    isFilterExist,
    handleFilterOpen,
    handleSortSelect,
    handleFilterButtonCheck,
    handleFilterTextChange,
    handleHospitalFilterReset,
    handleFilterResetAll,
    handleIsReservationAvailableChange,
    handleIsWorkingChange,
    handleFilterApply,
    handleCheckSimpleReservation,
    handleFilterParamsByQueryString,
  } = useHospitalFilter(FILTER_TYPE.HOSPITAL_TAB);

  const isFiltered = isFilterExist || isReservationAvailable || isWorking;

  const {
    trackingViewHospitalReservationTab,
    trackingClickHospitalReservationTabHospital,
    trackingClickHospitalReservationLocationFilter,
    trackingViewHospitaltabShortcut,
    // trackingClickHospitaltabShortcut,
  } = useEventTrackerHospitalTab();
  const { trackingViewHospitalReservationCurationItem } = useEventTrackerHospitalCuration();
  const { selectedAddress, setSelectedAddress } = useSelectedAddressStore();
  const addressData = AddressUtil.getAddressText(selectedAddress);
  const hospitalListParams = { ...filterParams, addressCode: selectedAddress.code };
  const {
    data: hospitalList,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetHospitalList(hospitalListParams);
  const { data: hospitalFilterListData, isFetched: isHospitalFilterListFetched } = useGetHospitalFilterList(
    selectedAddress.code || DEFAULT_NATIONWIDE_ADDRESS.code,
  );

  const hospitalListData = hospitalList?.pages.flatMap((item) => item.content);
  const { data: reviewWriteShortcutData } = useGetReviewWriteShortcut();
  const { data: liveHospitalIdData } = useGetLiveHospitalId({ ids: String(recentViewHospitalList?.[0]?.id || '') });
  const { data: bannerList, isLoading: isBannerListLoading } = useGetBannerList({
    isDeleted: false,
    type: BANNER_TYPE.MAIN,
  });
  const { reservationData } = useUpdatedReservationData();
  const {
    data: hospitalCurationData,
    isLoading: isHospitalCurationLoading,
    refetch: refetchHospitalCuration,
  } = useGetHospitalCuration();
  const filteredHospitalCurationData = useMemo(
    () => hospitalCurationData?.data.filter((item) => !item.isDeleted) || [],
    [hospitalCurationData?.data],
  );

  const [addressCodeInQueryString, setAddressCodeInQueryString] = useState<string>();
  const { data: addressByCodeData, isFetched: isAddressByCodeDataFetched } =
    useGetAddressByCode(addressCodeInQueryString);
  const { handleMiddleBannerClick, handleTrackingBannerView, handleBannerListShuffle, areaBanner } =
    useHospitalBanner();
  useGetInitialLocation({ setSelectedAddress });

  const curationTotalNames = useMemo(() => {
    return filteredHospitalCurationData
      .map((item) => {
        const foundCuration = CURATION_LIST.find(
          (curation) => curation.type === convertUpperCaseCurationType(item.curationType),
        );
        return foundCuration?.title || '';
      })
      .filter((element) => element !== '');
  }, [filteredHospitalCurationData]);

  const handleSortOpen = useCallback(() => {
    sortModalProps.showModal();
  }, [sortModalProps]);

  const handleSetShowHospitalTab = useCallback(() => {
    setIsShowHospitalTab(false);
  }, []);

  const handleChangedAddressStore = useCallback(
    (address: Address) => {
      // 해당 storage는 addressCode를 2뎁스 까지만 사용하기 때문에 뒤에 00000을 붙여 형식을 맞춤
      const level2AddressCode = `${address.address1Code}${address.address2Code}00000`;
      WebStorage.setHospitalSearchAddressCode(level2AddressCode);
      setSelectedAddressToLocalStorage({ latitude: address.lat, longitude: address.lng });
      setSelectedAddress({ ...address, code: level2AddressCode, level: address.level === 3 ? 2 : address.level });
      handleFilterResetAll();
    },
    [handleFilterResetAll, setSelectedAddress],
  );

  const handleAddressChange = useCallback(
    (address: Address) => {
      const addressText = address.address2?.includes(ADDRESS_TYPE.ALL)
        ? address.address2
        : `${address.address1Shorts} ${address.address2}`;
      handleChangedAddressStore(address);
      trackingClickHospitalReservationLocationFilter({ locationFilter: addressText });
      showToast({ text: TOAST_MESSAGE.ADDRESS_CHANGE, margin: TOAST_MARGIN.LARGE });
      addressModalProps.hideModal();
    },
    [addressModalProps, handleChangedAddressStore, showToast, trackingClickHospitalReservationLocationFilter],
  );

  const handleCardClick = useCallback(
    (hospital: Hospital) => {
      const promotionEventHospital = getPromotionEventHospital({
        hospitalList: PROMOTION_EVENT_HOSPITAL_LIST,
        hospitalId: hospital.id,
      });
      const labelNames = getHospitalLabels({
        isIntolinkHospital: checkIsIntolinkHospital(hospital.intoLinkStatus),
        isReservationPossible: hospital.isReservationPossible,
        isAlliance: hospital.isAlliance,
        isPromotion: promotionEventHospital?.isPromotion || false,
      });
      const bannerText = hospital.isReservationPossible && !promotionEventHospital ? BASIC_EVENT_TEXT : '';

      trackingClickHospitalReservationTabHospital({
        hospitalId: hospital.id,
        hospitalName: hospital.name,
        isAlliance: hospital.isAlliance,
        isReservationAvailable: hospital.isReservationAvailable,
        isOpen: hospital.isWorking,
        tagNames: hospital.expertise || [],
        distance: hospital.distance,
        labelNames,
        bannerText,
        hospitalReviewTotalCount: hospital.reviewCount,
        hospitalReviewAvgscore: hospital.totalScore,
      });

      router
        .push({
          pathname: ROUTES.HOSPITALS.DETAIL,
          query: {
            [ROUTE_QUERY_KEYS.HOSPITAL_ID]: hospital.id,
            [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
            [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
          },
        })
        ?.then(() => {
          handleBannerListShuffle(areaBanner);
        });
      handleSetShowHospitalTab();
    },
    [
      areaBanner,
      handleBannerListShuffle,
      handleSetShowHospitalTab,
      router,
      trackingClickHospitalReservationTabHospital,
    ],
  );

  const { toggleHospitalScrapWithCheckLogin } = useHospitalScrap({ isAboveCTA: true });
  const handleScrapClick = useCallback(
    (hospital: Hospital) => {
      toggleHospitalScrapWithCheckLogin(hospital);
    },
    [toggleHospitalScrapWithCheckLogin],
  );

  const handleRenderItem = useMemo(
    () => (data: Hospital, index: number) => {
      const isMiddleBannerPosition = index + 1 === MIDDLE_BANNER_POSITION && !!areaBanner?.id; // 5 번째 자리인지 판단
      const isHidingBorderBottom =
        (hospitalListData && index === hospitalListData.length - 1) || isMiddleBannerPosition;
      return (
        <>
          <HospitalCard
            key={`hospital_list_${index}`}
            data={data}
            onCardClick={handleCardClick}
            onScrapClick={handleScrapClick}
            isHidingBorderBottom={isHidingBorderBottom}
          />
          {isMiddleBannerPosition && (
            <MiddleEventBanner
              isShowHospitalTab={isShowHospitalTab}
              banner={areaBanner}
              onBannerClick={handleMiddleBannerClick}
              onBannerView={handleTrackingBannerView}
            />
          )}
        </>
      );
    },
    [
      handleCardClick,
      handleMiddleBannerClick,
      handleScrapClick,
      handleTrackingBannerView,
      hospitalListData,
      isShowHospitalTab,
      areaBanner,
    ],
  );

  const handleMapButtonClick = useCallback(() => {
    removeSelectedAddressToLocalStorage();
    setSelectedAddressToLocalStorage({ latitude: selectedAddress.lat, longitude: selectedAddress.lng });
    router.push(`${ROUTES.HOSPITALS.SEARCH_MAP}`);
    handleSetShowHospitalTab();
  }, [handleSetShowHospitalTab, router, selectedAddress]);

  const handleAddressModalOpen = useCallback(() => {
    filterModalProps.hideModal();
    addressModalProps.showModal();
  }, [addressModalProps, filterModalProps]);

  const handleCheckAddressModalOpen = useCallback(() => {
    if (isFilterExist && isFilterModalExpired()) {
      filterResetModalProps.showModal();
    } else {
      addressModalProps.showModal();
    }
  }, [addressModalProps, filterResetModalProps, isFilterExist, isFilterModalExpired]);

  /*
  // 큐레이션 2차 배포 시점부터 숏컷 노출 숨김 처리 (모든 숏컷 타입 포함)
  // 큐레이션 2차 테스트 기간 동안으로 고려중 (추후 고도화 방향 모색)
  const handleGetShortcutType = useCallback(() => {
    // 리뷰 작성 숏컷이 존재할 경우
    if (reviewWriteShortcutData?.data) {
      return SHORTCUT_TYPE.CREATE_REVIEW;
    }

    // 예약 가능한 병원일 경우
    if (recentViewHospitalList[0]?.isReservationAvailable) {
      return SHORTCUT_TYPE.CREATE_RESERVATION;
    }

    return SHORTCUT_TYPE.VIEW_HOSPITAL;
  }, [reviewWriteShortcutData?.data, recentViewHospitalList]);

  숏컷 (리뷰 작성)
  const handleGoToWriteReview = useCallback(() => {
    if (reviewWriteShortcutData?.data) {
      trackingClickHospitaltabShortcut({
        locationId: reviewWriteShortcutData.data.id,
        locationName: reviewWriteShortcutData.data.name,
        isIntopet: checkIsIntolinkHospital(reviewWriteShortcutData.data.intoLinkStatus),
        buttonText: '리뷰 작성',
        shortcutType: 'hospital_review',
      });
    }

    router.push({
      pathname: ROUTES.HOSPITALS.REVIEW_CREATE,
      query: {
        [ROUTE_QUERY_KEYS.HOSPITAL_ID]: reviewWriteShortcutData?.data?.id,
        [ROUTE_QUERY_KEYS.PET_ID]: reviewWriteShortcutData?.data?.petId,
        [ROUTE_QUERY_KEYS.RESERVATION_ID]: reviewWriteShortcutData?.data?.hospitalReservationId,
      },
    });
  }, [router, reviewWriteShortcutData?.data, trackingClickHospitaltabShortcut]);

  숏컷 (예약하기)
  const handleGoToCreateReservation = useCallback(() => {
    trackingClickHospitaltabShortcut({
      locationId: recentViewHospitalList[0]?.id,
      locationName: recentViewHospitalList[0]?.name,
      isIntopet: checkIsIntolinkHospital(recentViewHospitalList[0]?.intoLinkStatus),
      buttonText: '예약하기',
      shortcutType: 'hospital_reservation',
    });

    router.push({
      pathname: ROUTES.HOSPITALS.DETAIL,
      query: {
        [ROUTE_QUERY_KEYS.HOSPITAL_ID]: recentViewHospitalList[0]?.id,
        [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
        [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
        [ROUTE_QUERY_KEYS.PATH_LOCATION]: EventTrackerPathLocation.HOSPITAL_SHORTCUT,
      },
    });
  }, [recentViewHospitalList, router, trackingClickHospitaltabShortcut]);

  숏컷 (병원 보기)
  const handleGoToHospitalDetail = useCallback(() => {
    trackingClickHospitaltabShortcut({
      locationId: recentViewHospitalList[0]?.id,
      locationName: recentViewHospitalList[0]?.name,
      isIntopet: checkIsIntolinkHospital(recentViewHospitalList[0]?.intoLinkStatus),
      buttonText: '병원 보기',
      shortcutType: 'hospital_general',
    });

    router.push({
      pathname: ROUTES.HOSPITALS.DETAIL,
      query: {
        [ROUTE_QUERY_KEYS.HOSPITAL_ID]: recentViewHospitalList[0]?.id,
        [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
        [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
        [ROUTE_QUERY_KEYS.PATH_LOCATION]: EventTrackerPathLocation.HOSPITAL_SHORTCUT,
      },
    });
  }, [router, recentViewHospitalList, trackingClickHospitaltabShortcut]);

  const handleGetShortcutClickHandler = useCallback(() => {
    // 리뷰 작성 숏컷이 존재할 경우
    if (reviewWriteShortcutData?.data) {
      return handleGoToWriteReview;
    }

    // 예약 가능한 병원일 경우
    if (recentViewHospitalList[0]?.isReservationAvailable) {
      return handleGoToCreateReservation;
    }

    return handleGoToHospitalDetail;
  }, [
    reviewWriteShortcutData?.data,
    recentViewHospitalList,
    handleGoToWriteReview,
    handleGoToCreateReservation,
    handleGoToHospitalDetail,
  ]);

  const shortcutType = handleGetShortcutType();
  const shortcutClickHandler = handleGetShortcutClickHandler();
  const currentDate = dayjs().format(DATE_FORMAT.DATE);
  const isEndDateExceeded = new Date(currentDate) >= new Date(recentViewHospitalList[0]?.endDate);
  const hasShortcut =
    reviewWriteShortcutData?.data ||
    (!reviewWriteShortcutData?.data && recentViewHospitalList.length > 0 && !isEndDateExceeded);
  */

  const handleDeepLinkFilterQueryParse = useCallback(
    (query: ParsedUrlQuery) => {
      const deepLinkFilterQuery: DeepLinkHospitalFilter = Object.entries(query).reduce((acc, [key, value]) => {
        if (value === 'true') {
          return { ...acc, [key]: true } as DeepLinkHospitalFilter;
        }
        if (!Number.isNaN(Number(value))) {
          return { ...acc, [key]: Number(value) } as DeepLinkHospitalFilter;
        }
        return { ...acc, [key]: value } as DeepLinkHospitalFilter;
      }, {} as DeepLinkHospitalFilter);

      handleFilterParamsByQueryString(deepLinkFilterQuery, hospitalFilterListData);

      if (Object.keys(deepLinkFilterQuery).length > 0) {
        setAddressCodeInQueryString('');
      }
    },
    [handleFilterParamsByQueryString, hospitalFilterListData],
  );

  const handleTrackingViewHospitalHome = useCallback(async () => {
    if (!isShowHospitalTab) {
      return;
    }

    const isNotInApp = !isInApp();
    const homeType = 'hospitalreservation';

    if (isNotInApp) {
      trackingViewHospitalHome({ homeType, locationDetail: addressData, locationAgree: false });
      setIsShowHospitalTab(false);
      return;
    }

    const result = await getLocationPermission();

    if (result?.status === false) {
      trackingViewHospitalHome({ homeType, locationDetail: addressData, locationAgree: false });
      setIsShowHospitalTab(false);
      return;
    }

    if (result?.status && !selectedAddress.isBeforeDefaultAddressInitialization) {
      trackingViewHospitalHome({ homeType, locationDetail: addressData, locationAgree: true });
      setIsShowHospitalTab(false);
    }
  }, [
    addressData,
    getLocationPermission,
    isShowHospitalTab,
    trackingViewHospitalHome,
    selectedAddress.isBeforeDefaultAddressInitialization,
  ]);

  useEffect(() => {
    refetchHospitalCuration();
  }, [refetchHospitalCuration, isLoggedIn]);

  useEffect(() => {
    const onforegroundDefault = window.onforeground;
    trackingViewHospitalReservationTab();

    window.onforeground = () => {
      onforegroundDefault?.();
      setIsShowHospitalTab(true);
      trackingViewHospitalReservationTab();
    };

    return () => {
      window.onforeground = onforegroundDefault;
    };
  }, [trackingViewHospitalReservationTab]);

  useEffect(() => {
    if (!reviewWriteShortcutData?.data) {
      return;
    }

    trackingViewHospitaltabShortcut({
      locationId: reviewWriteShortcutData?.data.id,
      locationName: reviewWriteShortcutData?.data.name,
      isIntopet: checkIsIntolinkHospital(reviewWriteShortcutData?.data.intoLinkStatus),
      buttonText: '리뷰 작성',
      shortcutType: 'hospital_review',
    });
  }, [reviewWriteShortcutData?.data, trackingViewHospitaltabShortcut]);

  useEffect(() => {
    if (flag) {
      return;
    }
    if (recentViewHospitalList.length < 1) {
      return;
    }

    trackingViewHospitaltabShortcut({
      locationId: recentViewHospitalList[0].id,
      locationName: recentViewHospitalList[0].name,
      isIntopet: checkIsIntolinkHospital(recentViewHospitalList[0].intoLinkStatus),
      buttonText: recentViewHospitalList[0].isReservationAvailable ? '예약하기' : '병원 보기',
      shortcutType: recentViewHospitalList[0].isReservationAvailable ? 'hospital_reservation' : 'hospital_general',
    });
    setFlag(true);
  }, [flag, recentViewHospitalList, trackingViewHospitaltabShortcut]);

  useEffect(() => {
    if (router.query.simpleReservation === 'true') {
      if (inAppBridge) {
        inAppBridge.getCurrentPosition();
      }

      handleCheckSimpleReservation();
    }
  }, [inAppBridge, router.query.simpleReservation, handleCheckSimpleReservation]);

  useEffect(() => {
    if (!router.query?.isDeepLink) {
      return;
    }

    if (router.query?.addressCode && router.query?.addressCode !== addressCodeInQueryString) {
      setAddressCodeInQueryString(router.query.addressCode as string);
    }

    if (addressByCodeData && JSON.stringify(addressByCodeData) !== JSON.stringify(selectedAddress)) {
      handleChangedAddressStore(addressByCodeData);
    }

    if (isHospitalFilterListFetched) {
      handleDeepLinkFilterQueryParse(router.query);
    }
  }, [
    addressByCodeData,
    addressCodeInQueryString,
    handleChangedAddressStore,
    handleDeepLinkFilterQueryParse,
    isHospitalFilterListFetched,
    isAddressByCodeDataFetched,
    router.query,
    selectedAddress,
  ]);

  useEffect(() => {
    WebStorage.removePageScrollYPosition(ROUTES.HOSPITALS.DETAIL);
    WebStorage.removePageScrollXPosition(ROUTES.HOSPITALS.CURATION_DETAIL);
  }, []);

  useEffect(() => {
    if (liveHospitalIdData?.data) {
      handleSetRecentViewHospitalList(liveHospitalIdData.data);
    }
  }, [liveHospitalIdData?.data, handleSetRecentViewHospitalList]);

  useEffect(() => {
    const onforegroundDefault = window.onforeground;
    handleTrackingViewHospitalHome();

    window.onforeground = () => {
      onforegroundDefault?.();
      setIsShowHospitalTab(true);
      handleTrackingViewHospitalHome();
    };

    return () => {
      window.onforeground = onforegroundDefault;
    };
  }, [handleTrackingViewHospitalHome]);

  useEffect(() => {
    if (filteredHospitalCurationData.length > 0) {
      trackingViewHospitalReservationCurationItem({
        curationTotalNames,
        curationTotalCount: filteredHospitalCurationData.length,
      });
    }
  }, [filteredHospitalCurationData, curationTotalNames, trackingViewHospitalReservationCurationItem]);

  return (
    <Container>
      <HomeHeader isReservationUpdated={reservationData?.length > 0} onSetShowHospitalTab={handleSetShowHospitalTab} />
      <ContentContainer>
        <Content>
          <EventBanner
            isShowHospitalTab={isShowHospitalTab}
            bannerList={bannerList}
            isBannerListLoading={isBannerListLoading}
          />

          {/* 
          // 큐레이션 2차 배포 시점부터 숏컷 노출 숨김 처리 (모든 숏컷 타입 포함)
          // 큐레이션 2차 테스트 기간 동안으로 고려중 (추후 고도화 방향 모색)
          {isReviewWriteShortcutLoading && (
            <ShortcutContainer>
              <LoadingSpinner />
            </ShortcutContainer>
          )}

          {!isReviewWriteShortcutLoading && hasShortcut && (
            <ShortcutContainer>
              {reviewWriteShortcutData?.data && (
                <Shortcut
                  type={shortcutType}
                  hospitalName={reviewWriteShortcutData?.data.name}
                  onClick={shortcutClickHandler}
                />
              )}

              {!reviewWriteShortcutData?.data && recentViewHospitalList.length > 0 && !isEndDateExceeded && (
                <Shortcut
                  type={shortcutType}
                  hospitalName={recentViewHospitalList[0]?.name}
                  onClick={shortcutClickHandler}
                />
              )}
            </ShortcutContainer>
          )} 
          */}

          {/* 병원 큐레이션 */}
          <HospitalCuraionListContainer>
            {isHospitalCurationLoading ? (
              <HospitalCurationListSkeleton />
            ) : (
              filteredHospitalCurationData.length > 0 && (
                <HospitalCurationList
                  filteredHospitalCurationData={filteredHospitalCurationData}
                  curationTotalNames={curationTotalNames}
                  curationTotalCount={filteredHospitalCurationData.length}
                />
              )
            )}
          </HospitalCuraionListContainer>

          <Separator border={8} color={GLOBAL_COLOR.GRAY_50} />
          <HospitalTabAddressCard addressData={addressData} onChangeButtonClick={handleCheckAddressModalOpen} />
          <HospitalFilter
            filterList={FILTER_LIST}
            isSelected={handleFilterButtonCheck}
            onFilterOpenClick={handleFilterOpen}
            onTextChange={handleFilterTextChange}
            onFilterResetAll={handleHospitalFilterReset}
            isFilterExist={isFilterExist}
          />
          <Separator border={1} color={GLOBAL_COLOR.GRAY_70} />
          <HospitalSubFilterContainer
            isReservationAvailable={isReservationAvailable}
            isWorking={isWorking}
            sort={sort}
            handleIsReservationAvailableChange={handleIsReservationAvailableChange}
            handleIsWorkingChange={handleIsWorkingChange}
            handleSortOpen={handleSortOpen}
          />
          <InfiniteScroll<Hospital>
            data={hospitalListData}
            renderItems={handleRenderItem}
            onFetchNext={fetchNextPage}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            skeleton={<HospitalCardSkeleton />}
            className={'hospital_list'}
            renderEmptyComponent={
              <EmptyComponent
                iconType={'icEmptySearch'}
                emptyText={'해당 지역에 결과가 없어요.'}
                leftButtonIcon={'icReset'}
                leftButtonText={isFiltered ? '검색 필터 초기화' : undefined}
                onLeftButtonClick={isFiltered ? handleFilterResetAll : undefined}
                rightButtonText={'지역 변경하기'}
                onRightButtonClick={handleAddressModalOpen}
                marginTop={60}
              />
            }
          />
        </Content>
        <AddressFilterModal
          addressModalProps={addressModalProps}
          selectedAddress={selectedAddress}
          onAddressChange={handleAddressChange}
        />
        <HospitalFilterModal
          filterModalProps={filterModalProps}
          filterList={filterList}
          filterItemList={hospitalFilterListData}
          handleFilterApply={handleFilterApply}
          selectedFilterType={selectedFilterType}
          filterType={FILTER_TYPE.HOSPITAL_TAB}
          listParams={hospitalListParams}
          listEmptyComponent={
            <EmptyComponent
              iconType={'icEmptySearch'}
              emptyText={'해당 지역에 적용 가능한 필터가 없어요.'}
              rightButtonText={'지역 변경하기'}
              onRightButtonClick={handleAddressModalOpen}
              marginTop={0}
            />
          }
        />
        <ListSortModal
          sort={sort}
          handleSortSelect={handleSortSelect}
          sortModalProps={sortModalProps}
          sortFilter={SORT_FILTER}
        />
        <ConfirmModal
          modalProps={filterResetModalProps}
          title={'적용하신 필터가 초기화 돼요\n지역을 변경하시겠어요?'}
          contents={
            <ResetModalContent>
              <Body3 fontColor={GLOBAL_COLOR.GRAY_700}>{'지역 변경 후 필터를 다시 적용해주세요.'}</Body3>
              <CheckBox
                isRound
                isChecked={isNotShowChecked}
                size={20}
                className={'reset_modal_checkbox'}
                onClick={handleNotShowCheck}>
                <span className={'reset_modal_checkbox_text'}>오늘 그만 보기</span>
              </CheckBox>
            </ResetModalContent>
          }
          confirmText={`지역 변경하기`}
          cancelText={'취소'}
          onConfirm={() => {
            addressModalProps.showModal();
            handleApplyResetModalNotShowToday();
          }}
          onCancel={() => {
            filterResetModalProps.hideModal();
            handleCancelNotShowCheck();
          }}
        />
        <TopButton className={'hospital_topButton'} />
        <MapButton onClick={handleMapButtonClick}>
          <FlatIcon iconType={'icPinFilled'} color={GLOBAL_COLOR.WHITE} size={20} />
          <Body1 fontColor={GLOBAL_COLOR.WHITE} fontWeight={700}>
            지도에서 찾기
          </Body1>
        </MapButton>
      </ContentContainer>
    </Container>
  );
};

export default HospitalTab;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .hospital_list {
    margin-bottom: ${toRem(80)};
  }

  .hospital_topButton {
    bottom: ${toRem(16)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${toRem(HEADER_HEIGHT)};
`;

const Content = styled.div``;

const MapButton = styled.div`
  display: flex;
  width: ${toRem(140)};
  height: ${toRem(44)};
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: ${GLOBAL_COLOR.GRAY_800};
  border-radius: ${toRem(50)};
  position: fixed;
  bottom: ${toRem(16)};
  right: -1px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: ${Z_INDEX.FLOAT_BUTTON};
  size: ${toRem(16)};
  font-weight: 700;
  line-height: ${toRem(20)};
  box-shadow: 0 ${toRem(2)} ${toRem(8)} rgba(0, 0, 0, 0.1);

  i {
    margin-right: ${toRem(4)};
  }
`;

const ResetModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .reset_modal_checkbox {
    margin-top: ${toRem(16)};
    gap: 0;
    align-items: center;
  }

  .reset_modal_checkbox .checkBoxLabel {
    margin-left: ${toRem(8)};
    gap: 0;
  }

  .reset_modal_checkbox .reset_modal_checkbox_text {
    color: ${GLOBAL_COLOR.GRAY_700};
    line-height: ${toRem(22)};
  }
`;

// const ShortcutContainer = styled.div`
//   width: 100%;
//   height: ${toRem(68)};
//   padding-left: ${toRem(16)};
//   padding-right: ${toRem(16)};
//   position: relative;
// `;

const HospitalCuraionListContainer = styled.div`
  margin-top: ${toRem(20)};
  margin-bottom: ${toRem(16)};
`;
