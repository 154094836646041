import shuffle from 'lodash/shuffle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Banner, BANNER_TARGET, BANNER_TYPE } from '@/api/banner';
import { PROD_FITPET_MALL_DOMAIN } from '@/constants/common';
import { PROMOTION_HOSPITAL_IDS } from '@/constants/policy';
import { ROUTES, ROUTE_QUERY_KEYS } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHospital from '@/hooks/useEventTrackerHospital';
import useSelectedAddressStore from '@/store/useSelectedAddressStore';
import { shuffleArray } from '@/utils/commonUtils';

// TODO: 임시 배너 보여줄 지역 코드 리스트, 향후 api 연동 시 삭제
const SHOW_BANNER_ADDRESSES = [
  '1100000000',
  '4128100000',
  '4128500000',
  '4128700000',
  '4136000000',
  '4119000000',
  '4117300000',
  '4117100000',
  '4145000000',
  '4129000000',
  '4131000000',
  '4113500000',
  '4113100000',
  '4113300000',
];

// TODO: 임시 배너 보여줄 서울 코드, 향후 api 연동 시 삭제
const SEOUL_ADDRESS_START_CODE = '11';

// TODO: 하드코딩 배너, 향후 api 연동 시 삭제
const MIDDLE_BANNERS: Banner[] = [
  {
    id: 47,
    image: 'images/hospital_middle_banner_find_hospital_cat_1.png',
    link: `${PROD_FITPET_MALL_DOMAIN}/events/find_hospital_cat_1`,
    name: '집사 J의 우리 고양이 좋은 병원 찾기 프로젝트',
    target: BANNER_TARGET.BLANK,
    type: BANNER_TYPE.MIDDLE_BANNER,
    order: 0,
    isAlwaysShowing: false,
  },
  {
    id: 47,
    image: 'images/hospital_middle_banner_24sd.png',
    link: `/hospitals/${PROMOTION_HOSPITAL_IDS.SD_HOSPITAL_ID}`,
    name: '오직 핏펫에서만 건강검진 20%',
    target: BANNER_TARGET.BLANK,
    type: BANNER_TYPE.MIDDLE_BANNER,
    order: 0,
    hospitalId: PROMOTION_HOSPITAL_IDS.SD_HOSPITAL_ID,
    isAlwaysShowing: false,
  },
  {
    id: 47,
    image: 'images/hospital_middle_banner_find_hospital_event_all.png',
    link: `${PROD_FITPET_MALL_DOMAIN}/events/find_hospital_event_all`,
    name: '버튼 하나로 예약하고 혜택도 챙겨 가세요!',
    target: BANNER_TARGET.BLANK,
    type: BANNER_TYPE.MIDDLE_BANNER,
    order: 0,
    isAlwaysShowing: true,
  },
];

const EVENT_VERSION = 'v3';
const SECTION_TITLE = '홈 미들배너';

const useHospitalBanner = () => {
  const router = useAppRouter();
  const { selectedAddress } = useSelectedAddressStore();
  const { trackingClickHospitalReservationHomeMiddleBanner, trackingViewHospitalReservationHomeMiddleBanner } =
    useEventTrackerHospital();

  const [areaBanners, setAreaBanners] = useState<Banner[]>([]);
  const [isBannerViewed, setIsBannerViewed] = useState(false);

  const isShowBanner = useMemo(() => {
    if (!selectedAddress?.code) {
      return false;
    }
    const isCurrentAddressIncluded = SHOW_BANNER_ADDRESSES.includes(selectedAddress.code);
    const isSeoulAddress = selectedAddress.code.startsWith(SEOUL_ADDRESS_START_CODE); // 서울시는 전체라 11로 시작하는 모든 addressCode 는 배너 보이도록
    return isCurrentAddressIncluded || isSeoulAddress;
  }, [selectedAddress.code]);

  const areaBanner = useMemo(() => {
    if (!areaBanners?.length) {
      return {} as Banner;
    }

    const shuffledMiddleBanners = shuffleArray(areaBanners);
    return shuffledMiddleBanners[0];
  }, [areaBanners]);

  const handleMiddleBannerClick = useCallback(
    (banner: Banner) => {
      trackingClickHospitalReservationHomeMiddleBanner({
        bannerIndex: 0,
        bannerItemId: banner.id,
        bannerItemName: banner.name ?? 'empty_string',
        eventVersion: EVENT_VERSION,
        sectionTitle: SECTION_TITLE,
      });

      if (banner.hospitalId) {
        router.push({
          pathname: ROUTES.HOSPITALS.DETAIL,
          query: {
            [ROUTE_QUERY_KEYS.HOSPITAL_ID]: banner.hospitalId,
            [ROUTE_QUERY_KEYS.IS_FULLSCREEN]: true,
            [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname,
          },
        });
      } else {
        router.push(banner.link);
      }
    },
    [router, trackingClickHospitalReservationHomeMiddleBanner],
  );

  const handleTrackingBannerView = useCallback(
    (banner: Banner) => {
      if (isBannerViewed) {
        return;
      }
      setIsBannerViewed(true);
      trackingViewHospitalReservationHomeMiddleBanner({
        bannerIndex: 0,
        bannerItemId: banner.id,
        bannerItemName: banner.name ?? 'empty_string',
        eventVersion: EVENT_VERSION,
        sectionTitle: SECTION_TITLE,
      });
    },
    [isBannerViewed, trackingViewHospitalReservationHomeMiddleBanner],
  );

  /**
   * @description 배너 리스트를 섞어서 다음 배너를 보여준다.
   */
  const handleBannerListShuffle = useCallback(
    (currentBanner: Banner) => {
      if (!areaBanners?.length || areaBanners.length < 2) {
        return;
      }
      const [, ...otherBanners] = areaBanners;
      setAreaBanners([...otherBanners, currentBanner]);
      setIsBannerViewed(false);
    },
    [areaBanners],
  );

  useEffect(() => {
    if (!isShowBanner) {
      const filteredBanners = MIDDLE_BANNERS.filter((item) => item.isAlwaysShowing);
      const banner = shuffle(filteredBanners)?.slice(0, 1);
      setAreaBanners(banner);
      return;
    }
    setAreaBanners(MIDDLE_BANNERS);
  }, [isShowBanner]);

  return {
    areaBanner,
    handleMiddleBannerClick,
    handleTrackingBannerView,
    handleBannerListShuffle,
  };
};

export default useHospitalBanner;
