import { PetType, PetTypeForTracker } from './constants';
import { ANIMAL } from '@/constants/common';
import { ROUTES } from '@/constants/routes';

export const convertPetTypeForTracker = (animal: ANIMAL) => {
  if (animal === ANIMAL.DOG) {
    return PetType.DOG;
  }
  if (animal === ANIMAL.CAT) {
    return PetType.CAT;
  }
  return PetType.ETC;
};

export const convertPetTypeSearchForTracker = (animal: ANIMAL) => {
  if (animal === ANIMAL.DOG) {
    return PetTypeForTracker.DOG;
  }
  if (animal === ANIMAL.CAT) {
    return PetTypeForTracker.CAT;
  }
  return PetTypeForTracker.ALL;
};

export const getCurationTabName = ({
  allDay,
  weekEnd,
  overNight,
}: {
  allDay: string | string[] | undefined;
  weekEnd: string | string[] | undefined;
  overNight: string | string[] | undefined;
}) => {
  const allDayText = allDay && '24시간';
  const weekEndText = weekEnd && '주말진료';
  const overNightText = overNight && '야간진료';
  const curationTabName = allDayText || weekEndText || overNightText || '';
  return curationTabName;
};

export const getPathPageForTracker = ({
  pathname,
  curationType,
}: {
  pathname: string | string[] | undefined;
  curationType?: string | string[] | undefined;
}) => {
  const routeMappings = {
    [ROUTES.HOME]: '/',
    [ROUTES.HOSPITALS.LIST]: '/hospitals',
    [ROUTES.RESERVATIONS.DETAIL]: '/reservations',
    [ROUTES.HOSPITALS.REVIEWS_DETAIL]: '/hospitals/reviews',
    [ROUTES.HOSPITALS.SEARCH_MAP]: '/hospitals/map',
    [ROUTES.HOSPITALS.CURATION_DETAIL]: `/hospitals/curations/${curationType}`,
    [ROUTES.HOSPITALS.RECENT_VISITED]: '/hospitals/recent-visited',
  };
  const customPath = routeMappings[String(pathname)];
  return customPath || `${process.env.NEXT_PUBLIC_FRONT_BASE_URL}${String(pathname)}`;
};
